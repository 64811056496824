import {DefaultTheme} from 'styled-components';

const color = {
  neutral: {
    white: '#FFFFFF',
  },
  yellow: {
    100: '#FFF9E4',
    200: '#FFEFB6',
    300: '#FFE074',
    400: '#FFCC17',
    500: '#FCBF00',
    600: '#CB9900',
    700: '#9E7600',
    800: '#684E00',
    900: '#483700',
  },
  gray: {
    10: '#FDFDFD',
    100: '#FAFBFD',
    200: '#F7F8F9',
    300: '#E9EBEE',
    400: '#C6C8CD',
    500: '#9AA1AB',
    600: '#666F7B',
    700: '#3A4552',
    800: '#2A323B',
    900: '#171D23',
    1000: '#161616',
  },
};

const textColor = {
  white: color.neutral.white,
  default: color.gray[900],
  secondary: color.gray[700],
  teritary: color.gray[600],
  high_emphasis: color.yellow[500],
  middle_emphasis: color.gray[400],
  placeholder: color.gray[500],
  disabled: color.gray[400],
};

const fontsFamily = {
  pretendard_bold: 'Pretendard Bold',
  pretendard_semibold: 'Pretendard SemiBold',
  pretendard_medium: 'Pretendard Medium',
  pretendard_regular: 'Pretendard Regular',
};

export const textStyle = {
  header: {
    h1_bold: {
      family: fontsFamily.pretendard_bold,
      size: 2.4,
      lineHeight: 3.6,
    },
    h2_bold: {
      family: fontsFamily.pretendard_bold,
      size: 2,
      lineHeight: 2.8,
    },
  },
  title: {
    t1_semibold: {
      family: fontsFamily.pretendard_semibold,
      size: 2,
      lineHeight: 2.8,
    },
  },
  subTitle: {
    sub1_semibold: {
      family: fontsFamily.pretendard_semibold,
      size: 1.8,
      lineHeight: 2.6,
    },
  },
  body: {
    body1_semibold: {
      family: fontsFamily.pretendard_semibold,
      size: 1.6,
      lineHeight: 2.2,
    },
    body1_medium: {
      family: fontsFamily.pretendard_medium,
      size: 1.6,
      lineHeight: 2.2,
    },
    body1_regular: {
      family: fontsFamily.pretendard_regular,
      size: 1.6,
      lineHeight: 2.2,
    },
    body2_semibold: {
      family: fontsFamily.pretendard_semibold,
      size: 1.5,
      lineHeight: 2.2,
    },
    body2_medium: {
      family: fontsFamily.pretendard_medium,
      size: 1.5,
      lineHeight: 2.2,
    },
    body2_regular: {
      family: fontsFamily.pretendard_regular,
      size: 1.5,
      lineHeight: 2.2,
    },
    body3_semibold: {
      family: fontsFamily.pretendard_semibold,
      size: 1.4,
      lineHeight: 2.0,
    },
    body3_medium: {
      family: fontsFamily.pretendard_medium,
      size: 1.4,
      lineHeight: 2.0,
    },
    body3_regular: {
      family: fontsFamily.pretendard_regular,
      size: 1.4,
      lineHeight: 2.0,
    },
  },
};

export type ColorTypes = typeof color;
export type FontTypes = typeof fontsFamily;
export type FontColorTypes = typeof textColor;
export type textStyleTypes = typeof textStyle;

export const theme: DefaultTheme = {
  color,
  fontsFamily,
  textColor,
  textStyle,
};

export default theme;
