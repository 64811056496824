import {createGlobalStyle} from 'styled-components';
import theme from './theme';

// 반응형 미디어 사이즈 정의
const size = {
  mobile: '768px',
  desktopSmall: '1280px',
  desktop: '1920px',
};

// 디바이스 정의 by Matthias
export const device = {
  small: `max-width: ${size.mobile}`,
  medium: `max-width: ${size.desktopSmall}`,
  large: `max-width: ${size.desktop}`,
};

export const GlobalStyle = createGlobalStyle`
html {
font-size: 62.5%; // 1rem = 10px 로 변경 한 것
// 참고링크 = https://stackoverflow.com/questions/68790660/setting-root-font-size-not-affecting-rem-units-in-safari-for-margin-padding-et
// 128px => 12.8rem , 4px => 0.4rem 가능!
/* 
    @media all and (max-width: ${size.desktopSmall}) {
        font-size: 31.25%;
    } */
}

body {
    background: #F7F7F7;
    margin: 0;
    padding: 0;
    font-family: ${props => props.theme.fontsFamily.pretendard_regular};
    display: flex;
    flex-direction: column;
    align-items: center;
/*     
    ::-webkit-scrollbar{
      width: 0.8rem;
    }
    ::-webkit-scrollbar-track{
      background-color: rgba(0,0,0,0);
    }
    ::-webkit-scrollbar-thumb{
      border-radius: 1rem;
      background-color: ${theme.color.gray[300]};
    } */
}
`;
