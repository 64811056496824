import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './styles/fonts/font.css';
import React, {StrictMode, Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import packageJson from '../package.json';
import {RecoilRoot} from 'recoil';
import {GlobalStyle} from './styles/styles';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import theme from 'styles/theme';

// 이외에 필요한 스타일 파일이 있다면 import 해주세요.

console.log(`%cProject Name : ${packageJson.name}`, 'color:yellow');
console.log(`%Project Version : ${packageJson.version}`, 'color:yellow');
console.log(`%c Project Made by : ${packageJson.author}`, 'color:yellow');

const loading = <div>화면을 불러오는 중 입니다.</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./pages/login'));
const Oauth = React.lazy(() => import('./pages/oauth'));
const Page404 = React.lazy(() => import('./pages/page404/Page404'));
const Page500 = React.lazy(() => import('./pages/page500/Page500'));
const Home = React.lazy(() => import('./pages/home'));
const Ranking = React.lazy(() => import('./pages/ranking'));
const Recording = React.lazy(() => import('./pages/recording'));
const Records = React.lazy(() => import('./pages/records'));
const Mypage = React.lazy(() => import('./pages/mypage'));
const EditProfile = React.lazy(() => import('./pages/editProfile'));

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <GlobalStyle />
        <BrowserRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route path='/login' element={<Login />} />
              <Route path='/oauth' element={<Oauth />} />
              <Route path='/' element={<Home />} />
              <Route path='/ranking' element={<Ranking />} />
              <Route path='/recording' element={<Recording />} />
              <Route path='/records' element={<Records />} />
              <Route path='/mypage' element={<Mypage />} />
              <Route path='/editProfile' element={<EditProfile />} />
              <Route path='/404' element={<Page404 />} />
              <Route path='/500' element={<Page500 />} />
              <Route path='/*' element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </RecoilRoot>
    </ThemeProvider>
  </StrictMode>,
);
